.fs-lobster-regular {
  font-family: "Lobster", serif;
  font-weight: 400;
  font-style: normal;
}
.fs-lilita-one-regular {
  font-family: "Lilita One", serif;
  font-weight: 100;
  font-style: normal;
}
.text-10x{
  font-size: 27px;
}
.text-9x{
  font-size: 26px;
}
.text-8x{
  font-size: 25px;
}
.text-7x{
  font-size: 24px;
}
.text-6x{
  font-size: 23px;
}
.text-5x{
  font-size: 22px;
}
.text-4x{
  font-size: 21px;
}
.text-3x{
  font-size: 20px;
}
.text-2x{
  font-size: 19px;
}
.text-1x{
  font-size: 18px;
}
.text-n1x{
  font-size: 17px;
}
.text-n2x{
  font-size: 16px;
}
.text-n3x{
  font-size: 15px;
}
.text-n4x{
  font-size: 14px;
}
.text-n5x{
  font-size: 13px;
}
.text-n6x{
  font-size: 12px;
}
.text-n7x{
  font-size: 11px;
}
.text-n8x{
  font-size: 10px;
}
.text-n9x{
  font-size: 9px;
}
.text-n10x{
  font-size: 8px;
}

.text-royal-blue{  
  color:#2E9AFE;
}

.text-orange{
  color: #FFA500;
}

.text-dark-blue{
  color: #00008B;
}
.text-yellow{
  color:#FFFF00;
}

.bg-royal-blue{
  background-color:#2E9AFE;
}

.bg-floral-white{
  background-color: #FFFAF0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #E6E6E6;
  overflow: hidden;
}
